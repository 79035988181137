import React from 'react'

const PrintableStudentProfile = ({
  student,
  organizations,
  formatDate,
  studentClassrooms,
  classrooms,
  filteredNotes,
}) => {
  const orgName = organizations?.find((org) => org._id === student.organization)
  console.log('student', student)
  return (
    <div className='printContainer'>
      <div className='printSection'>
        <div className='printSectionHeaderContainer '>
          <div className='printSectionTitleContainer'>
            <div className='printSectionTitlePoint'></div>
            <h1 className='printSectionTitle'>
              Profiel van {student?.firstName} {student?.lastName}
            </h1>
          </div>
        </div>

        <div className='printMainInfoContainer'>
          {student?.image === '' ? (
            <img
              className='printableProfileImage'
              src='/images/profile.jpeg'
            ></img>
          ) : (
            <img className='printableProfileImage' src={student?.image} />
          )}

          <div className='printMainInfo'>
            <div>
              <i>Voornaam:</i>
              <p>{student?.firstName}</p>
            </div>
            <div>
              <i>Achternaam:</i>
              <p>{student?.lastName}</p>
            </div>
            <div>
              <i>Geboortedatum:</i>
              <p className='printDateText'>{formatDate(student?.birthDate)}</p>
            </div>
            <div>
              <i>Geslacht:</i>
              <p>{student?.sex}</p>
            </div>
            <div>
              <i>Telefoon:</i>
              <p>{student?.telephone}</p>
            </div>
            <div>
              <i>E-mailadres:</i>
              <p>{student?.email}</p>
            </div>
            <div>
              <i>Aankomstdatum Nederland:</i>
              <p className='printDateText'>
                {formatDate(student?.arrivalDate)}
              </p>
            </div>
            <div>
              <i>Registratie Datum Wereldwijd:</i>
              <p className='printDateText'>
                {formatDate(student?.registrationDate)}
              </p>
            </div>

            <div>
              <i>Land van Herkomst:</i>
              <p>{student?.country}</p>
            </div>

            <div>
              <i>Status Houder:</i>
              <p>{student?.permitHolder === 'false' || student?.permitHolder === '' ? 'Nee' : 'Ja'}</p>
            </div>
            <div>
              <i>Instantie:</i>
              <p>{orgName?.name}</p>
            </div>
          </div>
        </div>

        {/* <div className='printTopBoxText'></div> */}
      </div>

      {studentClassrooms.length > 0 && (
        <div className='printSection'>
          <div className='printSectionHeaderContainer '>
            <div className='printSectionTitleContainer'>
              <div className='printSectionTitlePoint'></div>
              <h1 className='printSectionTitle'>
                Lessen {student?.firstName} {student?.lastName}
              </h1>
            </div>
          </div>

          <div className='printStudentClassrooms'>
            {studentClassrooms?.map((studentClassroom, index) => {
              const foundClassroom = classrooms.find(
                (cl) => cl._id === studentClassroom.classroom
              )

              return (
                <div className='printStudentClassroomCard'>
                  <div>
                    <i>Naam van de klas:</i>
                    <p>{foundClassroom?.name}</p>
                  </div>
                  <div>
                    <i>Periode van studeren:</i>
                    <p className='printDateText'>
                      {formatDate(studentClassroom?.startDate)} -{' '}
                      {formatDate(studentClassroom?.endDate)
                        ? formatDate(studentClassroom?.endDate)
                        : '...'}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}

      {filteredNotes.length > 0 && (
        <div className='printSection'>
          <div className='printSectionHeaderContainer '>
            <div className='printSectionTitleContainer'>
              <div className='printSectionTitlePoint'></div>
              <h1 className='printSectionTitle'>
                Aantekeningen {student?.firstName} {student?.lastName}
              </h1>
            </div>
          </div>
          <div className='printStudentNotes'>
            {filteredNotes?.map((note, index) => {
              return (
                <div className='printStudentNoteCard'>
                  <div className='printStudentCardHeaderContainer'>
                    <i>{formatDate(note.date)}</i> <i>{note.category}</i>
                  </div>

                  <p>{note.description}</p>

                  <i>{note.authorName}</i>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default PrintableStudentProfile
