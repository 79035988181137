import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import '../styles/studentProfile.css'
import StudentClassroomCard from '../components/StudentClassroomCard'
import StudentStatusCard from '../components/StudentStatusCard'
import useAuth from '../hooks/useAuth'
import { v4 as uuidv4 } from 'uuid'
import StudentAttachmentCard from '../components/StudentAttachmentCard'
import '../styles/printableStudentProfile.css'
import PrintableStudentProfile from '../printable/PrintableStudentProfile'
import { UserRound, CirclePlus, X, CircleSlash2, Save, Printer, Filter, CircleCheckBig, Trash2 } from 'lucide-react'

const StudentProfile = () => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const params = useParams()
  const navigate = useNavigate()
  const currentDate = new Date(Date.now())
  const formattedDate = currentDate.toISOString().split('T')[0]
  const uniqueId = uuidv4()
  const isAdmin = auth.roles.includes(1991)

  const [isUpdating, setUpdating] = useState(false)
  const [updated, setUpdated] = useState(false)

  const [student, setStudent] = useState({
    image: '',

    firstName: '',
    lastName: '',
    birthDate: '',
    sex: '',
    country: '',

    telephone: '',
    email: '',
    arrivalDate: '',
    registrationDate: '',

    status: '',
    statusDate: '',

    // react gives warning if select option equals null,
    // mongoose throws error if value equals empty string
    // since it expects a reference objectId
    organization: '',

    permitHolder: '',

    contactPersonName: '',
    contactPersonJobDescription: '',
    contactPersonTelephone: '',
    contactPersonEmail: '',
  })

  const [studentStatuses, setStudentStatuses] = useState([])
  const [studentClassrooms, setStudentClassrooms] = useState([])
  const [studentNotes, setStudentNotes] = useState([])
  const [newNote, setNewNote] = useState({
    date: formattedDate,
    authorName: auth.userName,
    author: auth.userId,
    category: 'Andere', // by default
    description: '',
  })
  // to know which note it is among notes
  const [noteIndex, setNoteIndex] = useState(null)
  // we temporarily have this object once we are about to edit a note
  // we spread the note for edition from the list of notes
  const [editedNote, setEditedNote] = useState({})
  // selected notes filter
  const [selectedNotesCategories, setSelectedNotesCategories] = useState([])

  const filteredNotes = selectedNotesCategories.length
    ? studentNotes.filter((note) =>
        selectedNotesCategories.includes(note.category)
      )
    : studentNotes

  const [studentAttachments, setStudentAttachments] = useState([])
  const [studentNewAttachments, setStudentNewAttachments] = useState([])

  const [isCloseConfirmationWindowOn, setCloseConfirmationWindowOn] = useState(
    false
  )

  const [deletedStudentClassrooms, setDeletedStudentClassrooms] = useState([])
  const [deletedStudentStatuses, setDeletedStudentStatuses] = useState([])
  const [deletedStudentNotes, setDeletedStudentNotes] = useState([])
  const [deletedStudentAttachments, setDeletedStudentAttachments] = useState([])

  const [errMsg, setErrMsg] = useState('')

  const [nameValError, setNameValError] = useState('')
  const [classroomValError, setClassroomValError] = useState('')
  const [statusValError, setStatusValError] = useState('')

  const sexList = ['Man', 'Vrouw', 'X']
  const booleanList = ['true', 'false']
  const noteHeaders = ['Datum', 'Auteur', 'Categorie', 'Beschrijving']
  const categoryNames = ['Evaluatie', 'Status', 'Klas', 'Andere']

  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/students/student/${params.id}`
        )

        const {
          student,
          studentClassrooms,
          studentStatuses,
          studentNotes,
          studentAttachments,
        } = data

        console.log('student', student) 

        const obj = student
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop) && typeof obj[prop] === 'string') {
            const dateObj = new Date(obj[prop])
            if (!isNaN(dateObj)) {
              const day = String(dateObj.getDate()).padStart(2, '0')
              const month = String(dateObj.getMonth() + 1).padStart(2, '0')
              const year = dateObj.getFullYear()
              obj[prop] = `${year}-${month}-${day}`
            }
          }
        }
        setStudent(obj)

        const tempStudentClassroomsArray = studentClassrooms?.map((obj) => {
          if (obj.startDate && obj.startDate !== 'null') {
            obj.startDate = new Date(obj.startDate).toISOString().split('T')[0]
          }
          if (obj.endDate && obj.endDate !== 'null') {
            obj.endDate = new Date(obj.endDate).toISOString().split('T')[0]
          }
          return obj
        })
        setStudentClassrooms(tempStudentClassroomsArray)

        const tempStudentStatuses = studentStatuses?.map((obj) => {
          if (obj.date && obj.date !== 'null') {
            obj.date = new Date(obj.date).toISOString().split('T')[0]
          }

          return obj
        })
        setStudentStatuses(tempStudentStatuses)

        const tempStudentNotes = studentNotes?.map((obj) => {
          if (obj.date && obj.date !== 'null') {
            obj.date = new Date(obj.date).toISOString().split('T')[0]
          }

          return obj
        })
        setStudentNotes(tempStudentNotes)

        setStudentAttachments(studentAttachments)
      } catch (error) {}
    }
    fetchStudent()
  }, [])

  const handleEditedNoteInput = (event) => {
    const data = { ...editedNote }
    data[event.target.name] = event.target.value
    setEditedNote(data)
  }

  const openEditedNote = (index) => {
    setNoteIndex(index)
    setEditedNote(studentNotes[index])
  }

  const cancelEditedNote = () => {
    setNoteIndex(null)
    setEditedNote({})
  }

  const saveEditedNote = () => {
    const data = [...studentNotes]
    data[noteIndex] = editedNote
    setStudentNotes(data)
    setNoteIndex(null)
    setEditedNote({})
  }

  const [filterToggle, setFilterToggle] = useState(false)
  const [newNoteToggle, setNewNoteToggle] = useState(false)

  const handleNewNoteInput = (event) => {
    let data = { ...newNote }
    data[event.target.name] = event.target.value
    setNewNote(data)
  }

  const cancelNewNote = () => {
    setNewNote({
      date: formattedDate,
      authorName: auth.userName,
      author: auth.userId,
      category: 'Andere', // by default
      description: '',
    })
    setNewNoteToggle(false)
  }

  const saveNewNote = () => {
    const data = [...studentNotes]
    data.unshift(newNote)
    setStudentNotes(data)
    setNewNote({
      date: formattedDate,
      authorName: auth.userName,
      author: auth.userId,
      category: 'Andere', // by default
      description: '',
    })
    setNewNoteToggle(false)
  }

  const deleteNote = (index) => {
    const data = [...studentNotes]
    data.splice(index, 1)
    setStudentNotes(data)

    // for backend if the save button is clicked
    // we want to loop through all deleted statuses in the backend
    // we want to add to the delete list only those docs that are already existing in DB in other words the ones that have an id
    if (studentNotes[index]._id !== undefined) {
      setDeletedStudentNotes([...deletedStudentNotes, studentNotes[index]])
    }
  }

  const deleteAttachment = (index) => {
    const data = [...studentAttachments]
    data.splice(index, 1)
    setStudentAttachments(data)
    // for backend if the save button is clicked
    // we want to loop through all deleted attachments in the backend
    // we want to add to the delete list only those docs that are already existing in DB in other words the ones that have an id
    // since there is no need to delete in DB the ones that have been added recently on frontend, but haven't saved yet
    if (studentAttachments[index]._id !== undefined) {
      setDeletedStudentAttachments([
        ...deletedStudentAttachments,
        studentAttachments[index],
      ])
    }
  }

  const [classrooms, setClassrooms] = useState([])
  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/classrooms`
        )
        setClassrooms(data)
      } catch (error) {
        // setErrMsg(error.response.data.message)
      }
    }
    fetchClassrooms()
  }, [])

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/organizations`
        )
        setOrganizations(data)
        //
      } catch (error) {
        // setErrMsg(error.response.data.message)
      }
    }

    fetchOrganizations()
  }, [])

  const handleStudentInput = (event) => {
    let data = { ...student }
    data[event.target.name] = event.target.value
    setStudent(data)
  }

  const addClassroom = (e) => {
    const data = [...studentClassrooms]
    data.unshift({
      classroom: '',
      startDate: '',
      endDate: '',
      // status: 'true',
      uniqueId: uniqueId,
    })
    setStudentClassrooms(data)
  }

  const deleteClassroom = (id) => {
    // created classrooms in this session have uniqueId, but the ones from DB have _id

    // for frontend
    const filteredClassrooms = studentClassrooms.filter((element) => {
      if (element.uniqueId) {
        return element.uniqueId !== id
      } else {
        return element._id !== id
      }
    })
    setStudentClassrooms(filteredClassrooms)

    // for backend
    const found = studentClassrooms.find((element) => {
      if (element.uniqueId) {
        return element.uniqueId === id
      } else {
        return element._id === id
      }
    })
    // we want to loop through all deleted classrooms in the backend
    // we want to add to the delete list only those docs that are already existing in DB which have an _id
    if (found._id) {
      setDeletedStudentClassrooms([...deletedStudentClassrooms, found])
    }
  }

  const handleStudentClassroomInput = (event, index) => {
    let data = [...studentClassrooms]
    data[index][event.target.name] = event.target.value
    setStudentClassrooms(data)
  }

  const addStatus = (e) => {
    // we want to make sure that the user is reminded of adding a note regarding the new status
    setStatusValError('Vergeet niet een aantekening toe te voegen')
    setTimeout(() => {
      setStatusValError('')
      const data = [...studentStatuses]

      // we want to create active and inactive statuses in turns
      // and also if there is no initial status, we want to create the active one
      if (data[0]?.status !== 'true' || data.length === 0) {
        data.unshift({
          status: 'true',
          date: '',
          uniqueId: uniqueId,
        })
      } else {
        data.unshift({
          status: 'false',
          date: '',
          uniqueId: uniqueId,
        })
      }
      setStudentStatuses(data)
    }, 2000)
  }

  const deleteStatus = (id) => {
    // for frontend
    const filteredStatuses = studentStatuses.filter((element) => {
      if (element.uniqueId) {
        return element.uniqueId !== id
      } else {
        return element._id !== id
      }
    })
    setStudentStatuses(filteredStatuses)

    // for backend if the save button is clicked
    const found = studentStatuses.find((element) => {
      // created statuses in this session have uniqueId, but the ones from DB have _id
      if (element.uniqueId) {
        return element.uniqueId === id
      } else {
        return element._id === id
      }
    })

    // we want to loop through all deleted statuses in the backend
    // we want to add to the delete list only those docs that are already existing in DB which have an _id
    if (found._id) {
      setDeletedStudentStatuses([...deletedStudentStatuses, found])
    }
  }

  const handleStudentStatusInput = (event, index) => {
    let data = [...studentStatuses]
    // // we care about the latest status of the student,
    // // since we unshifting objects into the array of studentStatuses,
    // // if the first element and only if it is being turned to inactive,
    // // while there is any classroom is still active, we throw an error
    // if (data[0] && event.target.value === 'false') {
    //   if (studentClassrooms.some((obj) => obj.status === 'true')) {
    //     setStatusValError('Deze leerling heeft actieve klassen')
    //     setTimeout(() => {
    //       setStatusValError('')
    //     }, 3500)
    //     return
    //   }
    // }
    data[index][event.target.name] = event.target.value
    setStudentStatuses(data)
  }

  // const handleStudentImage = (e) => {
  //   const reader = new FileReader()
  //   reader.readAsDataURL(e.target.files[0])

  //   reader.onload = () => {
  //     const image = reader.result
  //     setStudent({ ...student, image })
  //   }
  // }

  const handleStudentImage = (e) => {
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = () => {
      // AI generated part for compressing the image
      const img = new Image()
      img.src = reader.result
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const maxWidth = 800 // Maximum width for the compressed image
        const maxHeight = 600 // Maximum height for the compressed image
        let width = img.width
        let height = img.height

        if (width > height && width > maxWidth) {
          height *= maxWidth / width
          width = maxWidth
        } else if (height > width && height > maxHeight) {
          width *= maxHeight / height
          height = maxHeight
        }

        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)

        const compressedImage = canvas.toDataURL('image/jpeg', 0.6) // Adjust compression quality as needed

        setStudent({ ...student, image: compressedImage })
      }
      // AI generated part for compressing the image
    }
  }

  const handleStudentAttachments = (e) => {
    const filesList = Object.values(e.target.files)
    //
    setStudentNewAttachments(filesList)
  }

  useEffect(() => {
    setNameValError('')
    setClassroomValError('')
    setStatusValError('')
  }, [student.firstName, student.lastName, studentClassrooms, studentStatuses])

  const handleSubmit = async (event) => {
    // show error message if name is not filled
    if (!student.firstName || !student.lastName) {
      setNameValError('Vul de naam van de leerling in')
      setCloseConfirmationWindowOn(false)
      return
    }

    // show error message if any classroom does not have the classroom's name and/or starting date
    let allClassroomsValid = true
    for (let i = 0; i < studentClassrooms.length; i++) {
      const classroom = studentClassrooms[i].classroom
      const startDate = studentClassrooms[i].startDate
      // const endDate = studentClassrooms[i].endDate

      // if (!classroom || !startDate || !endDate) {
      if (!classroom || !startDate) {
        allClassroomsValid = false

        break
      }
    }

    if (!allClassroomsValid) {
      setClassroomValError(
        'U hebt geen klas en/of begin datum en/of einddatum gekozen'
      )
      setCloseConfirmationWindowOn(false)
      return
    }

    let allStatusesValid = true
    for (let i = 0; i < studentStatuses.length; i++) {
      const status = studentStatuses[i].status
      const date = studentStatuses[i].date

      if (!status || !date) {
        allStatusesValid = false
        break
      }
    }

    if (studentStatuses.length === 0 || !allStatusesValid) {
      setStatusValError(
        'U heeft geen status of startdatum van de status gekozen'
      )
      setCloseConfirmationWindowOn(false)
      return
    }

    try {
      setUpdating(true)
      const formData = new FormData()

      formData.append('student', JSON.stringify(student))
      formData.append('studentClassrooms', JSON.stringify(studentClassrooms))
      formData.append(
        'deletedStudentClassrooms',
        JSON.stringify(deletedStudentClassrooms)
      )
      formData.append('studentStatuses', JSON.stringify(studentStatuses))
      formData.append(
        'deletedStudentStatuses',
        JSON.stringify(deletedStudentStatuses)
      )
      formData.append('studentNotes', JSON.stringify(studentNotes))
      formData.append(
        'deletedStudentNotes',
        JSON.stringify(deletedStudentNotes)
      )

      studentNewAttachments.forEach((element) => {
        // we should provide the same property key name in the backend on the route for multer
        formData.append('attachments', element) // each file should be appended separately
      })

      formData.append(
        'deletedStudentAttachments',
        JSON.stringify(deletedStudentAttachments)
      )

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axiosPrivate.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/students/student/${params.id}`,
        formData,
        config
      )

      // if the modal is open,
      // after saving navigate to students list
      if (isCloseConfirmationWindowOn) {
        navigate('/students')
      }

      // if it is being submitted with the save button, which means that the modal is not open
      // then we want to receive all updated info and set them in states to display
      // otherwise the info is being submitted/save on "close window" button
      if (!isCloseConfirmationWindowOn) {
        // if it was successfully updated, then show a notification for a small period of time

        setStudentNewAttachments([])
        setDeletedStudentClassrooms([])
        setDeletedStudentStatuses([])
        setDeletedStudentNotes([])
        setDeletedStudentAttachments([])

        const obj = data.student
        for (const prop in obj) {
          //
          //
          if (obj.hasOwnProperty(prop) && typeof obj[prop] === 'string') {
            //
            const dateObj = new Date(obj[prop])
            if (!isNaN(dateObj)) {
              //
              const day = String(dateObj.getDate()).padStart(2, '0')
              const month = String(dateObj.getMonth() + 1).padStart(2, '0')
              const year = dateObj.getFullYear()
              obj[prop] = `${year}-${month}-${day}`
            }
          }
        }
        setStudent(obj)
        const tempStudentClassroomsArray = data.studentClassrooms?.map(
          (obj) => {
            if (obj.startDate && obj.startDate !== 'null') {
              obj.startDate = new Date(obj.startDate)
                .toISOString()
                .split('T')[0]
            }
            if (obj.endDate && obj.endDate !== 'null') {
              obj.endDate = new Date(obj.endDate).toISOString().split('T')[0]
            }
            return obj
          }
        )
        setStudentClassrooms(tempStudentClassroomsArray)
        const tempStudentStatuses = data.studentStatuses?.map((obj) => {
          if (obj.date && obj.date !== 'null') {
            obj.date = new Date(obj.date).toISOString().split('T')[0]
          }

          return obj
        })
        setStudentStatuses(tempStudentStatuses)
        const tempStudentNotes = data.studentNotes?.map((obj) => {
          if (obj.date && obj.date !== 'null') {
            obj.date = new Date(obj.date).toISOString().split('T')[0]
          }

          return obj
        })
        setStudentNotes(tempStudentNotes)
        setStudentAttachments(data.studentAttachments)
        if (data) {
          setTimeout(() => {
            setUpdated(false)
          }, 1000)
          setUpdated(true)
        }
      }

      setCloseConfirmationWindowOn(false)
    } catch (error) {
      setNameValError(error.response.data.message)
    } finally {
      setUpdating(false)
    }
  }

  const getCurrentDate = (index) => {
    const currentDate = new Date(studentClassrooms[index].startDate)
    const year = currentDate.getFullYear()
    let month = currentDate.getMonth() + 1 // Month is zero-based
    let day = currentDate.getDate()

    if (month < 10) {
      month = `0${month}`
    }

    if (day < 10) {
      day = `0${day}`
    }

    return `${year}-${month}-${day}`
  }

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-')
      return `${day}/${month}/${year}`
    }
  }

  return (
    <>
      <section className='popupContainer'>
        {isCloseConfirmationWindowOn && (
          <div className='closeModalContainer'>

            <div className='closeConfirmationBox bigclosebox'>
              <article>
                <h1>Opslaan voordat u afsluit?</h1>
                <button    onClick={() => {
                  setCloseConfirmationWindowOn(false)
                }}>
                  <X strokeWidth={1} stroke='gray'/>
                </button>
              </article>
              <p>
              Hier hebt u drie opties: klik op "Ja" om de wijzigingen op te slaan, "Nee" om ze te negeren, of annuleer door op de 'x' in de rechterbovenhoek te klikken.
              </p>
              <div className='confirmationButtonsContainer'>
              <button style={{maxWidth: 'fit-content'}}
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Ja
                  <CircleCheckBig width='15' stroke='white'/>
                </button>
                <button className='nee-button' style={{maxWidth: 'fit-content'}}
                  onClick={() => {
                    setCloseConfirmationWindowOn(false)
                    navigate('/students')
                  }}
                >
                  Nee
                  <CircleSlash2 width='15' stroke='white'/>
                </button>
              </div>
            </div>
          </div>
        )}
        <button
          onClick={() => {
            setCloseConfirmationWindowOn(true)
          }}
          className='popupContainerClose'
        >
          <img src='/images/close.png' alt=''></img>
        </button>
        <div className='studentModule'>
          <div className='sectionContainer studentSection profile'>
            <div className='sectionHeaderContainer'>
              <div className='sectionTitleContainer'>
                <div className='sectionTitlePoint'></div>
                <h1 className='sectionTitle'>Profiel</h1>
              </div>
              <div className='sectionControlsContainer'>
                {isUpdating && (
                  <div className='notificationMessageInControls2 '>
                    <img alt=''
                      className='studentCardConfirmationButtonImage'
                      src='/animations/loading.gif'
                    />
                  </div>
                )}
                {updated && (
                  <div className='notificationMessageInControls2'>
                    <CircleCheckBig width='15' stroke='white'/>
                  </div>
                )}
                <div className='sectionControlContainer'>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      window.print()
                    }}
                    className='sectionControlButton'
                  >
                      <Printer stroke='white' width={15}/>
                  </button>
                </div>

                <div className='sectionControlContainer'>
                  <button
                    onClick={handleSubmit}
                    className='sectionControlButton'
                  >
                  <Save width='17' stroke='white'/>
                  </button>
                </div>
              </div>
            </div>
            {nameValError && <p className='cardErrorMessage'>{nameValError}</p>}
            <div className='studentScrollableBox'>
              <div className='studentProfileImageContainer'>
                <img alt=''
                  src='/images/ribbon_left.jpg'
                  className='profileImageRibbon leftRibbon'
                ></img>

                <img alt=''
                  src='/images/ribbon_right.jpg'
                  className='profileImageRibbon rightRibbon'
                ></img>

                <div className='studentProfileImageBox'>
                  {student?.image === '' ? (
                    // <img src='/images/avatar.png' alt=''></img>
                    <UserRound size='100' strokeWidth={1} stroke='var(--mainbg)' />
                  ) : (
                    <img src={student.image} alt=''/>
                  )}
                  <div className='imageShadow'></div>
                  <label className='studentProfileImageLabel'>
                    <input
                      type='file'
                      accept='image/*'
                      className='cardProfileImageInput'
                      onChange={handleStudentImage}
                    />
                  </label>
                </div>
              </div>

              <div className='studentDetailsBox'>
                <label htmlFor='firstName'>Voornaam</label>
                <input
                  type='text'
                  id='firstName'
                  name='firstName'
                  value={student.firstName}
                  onChange={handleStudentInput}
                ></input>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='lastName'>Achternaam</label>
                <input
                  type='text'
                  id='lastName'
                  name='lastName'
                  value={student.lastName}
                  onChange={handleStudentInput}
                ></input>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='birthDate'>Geboortedatum</label>
                <input
                  type='date'
                  id='birthDate'
                  name='birthDate'
                  value={student.birthDate || ''}
                  onChange={handleStudentInput}
                ></input>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='sex'>Geslacht</label>
                <select
                  className='studentDetailsBoxLabelPlaceholder'
                  id='sex'
                  name='sex'
                  onChange={handleStudentInput}
                  value={student.sex}
                >
                  <option value={''}>--Kies een geslacht--</option>
                  {sexList?.map((sex, index) => {
                    return (
                      <option value={sex} key={index}>
                        {sex}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='telephone'>Telefoon</label>
                <input
                  type='text'
                  id='telephone'
                  name='telephone'
                  value={student.telephone}
                  onChange={handleStudentInput}
                ></input>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='email'>E-mailadres</label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  value={student.email}
                  onChange={handleStudentInput}
                ></input>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='arrivalDate'>Aankomstdatum Nederland</label>
                <input
                  type='date'
                  id='arrivalDate'
                  name='arrivalDate'
                  value={student.arrivalDate || ''}
                  onChange={handleStudentInput}
                ></input>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='registrationDate'>
                  Registratie Datum WereldWijd
                </label>
                <input
                  type='date'
                  id='registrationDate'
                  name='registrationDate'
                  value={student.registrationDate || ''}
                  onChange={handleStudentInput}
                ></input>
              </div>

              <div className='studentDetailsBox'>
                <label htmlFor='country'>Land van Herkomst</label>
                <input
                  type='text'
                  id='country'
                  name='country'
                  value={student.country}
                  onChange={handleStudentInput}
                ></input>
              </div>

              <div className='studentDetailsBox'>
                <label htmlFor='permitHolder'>Status Houder</label>
                <select
                  id='permitHolder'
                  name='permitHolder'
                  onChange={handleStudentInput}
                  value={student.permitHolder}
                >
                  <option value={''}>--Kies een status--</option>
                  {booleanList?.map((status, index) => {
                    return (
                      <option value={status} key={index}>
                        {status === 'true' ? 'Ja' : 'Nee'}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='organization'>Instantie</label>
                <select
                  id='organization'
                  name='organization'
                  onChange={handleStudentInput}
                  value={student.organization || ''}
                >
                  <option value={''}>--Kies een instantie--</option>
                  {organizations?.map((org, index) => {
                    return (
                      <option value={org._id} key={index}>
                        {org.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='contactPersonName'>Contactpersoon Naam</label>
                <input
                  type='text'
                  id='contactPersonName'
                  name='contactPersonName'
                  value={student.contactPersonName}
                  onChange={handleStudentInput}
                ></input>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='contactPersonJobDescription'>
                  Functie Contactpersoon
                </label>
                <input
                  type='text'
                  id='contactPersonJobDescription'
                  name='contactPersonJobDescription'
                  value={student.contactPersonJobDescription}
                  onChange={handleStudentInput}
                ></input>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='contactPersonTelephone'>
                  Contactpersoon Telefoon
                </label>
                <input
                  type='text'
                  id='contactPersonTelephone'
                  name='contactPersonTelephone'
                  value={student.contactPersonTelephone}
                  onChange={handleStudentInput}
                ></input>
              </div>
              <div className='studentDetailsBox'>
                <label htmlFor='contactPersonEmail'>
                  Contactpersoon E-mailadres
                </label>
                <input
                  type='text'
                  id='contactPersonEmail'
                  name='contactPersonEmail'
                  value={student.contactPersonEmail}
                  onChange={handleStudentInput}
                ></input>
              </div>
            </div>
          </div>

          <div className='sectionContainer classrooms'>
            <div className='sectionHeaderContainer'>
              <div className='sectionTitleContainer'>
                <div className='sectionTitlePoint'></div>
                <h1 className='sectionTitle'>Klas</h1>
              </div>
              <div className='sectionControlsContainer'>
                <div className='sectionControlContainer'>
                  <button
                    onClick={addClassroom}
                    className='sectionControlButton'
                  >
                   <CirclePlus stroke='white'  width='15'/>
                  </button>
                </div>
              </div>
            </div>
            {classroomValError && (
              <p className='cardErrorMessage'>{classroomValError}</p>
            )}
            <div className='studentScrollableBox'>
              {studentClassrooms?.map((studentClassroom, index) => {
                return (
                  <StudentClassroomCard
                    key={studentClassroom._id || studentClassroom.uniqueId}
                    index={index}
                    studentClassroom={studentClassroom}
                    studentClassrooms={studentClassrooms}
                    deleteClassroom={deleteClassroom}
                    handleStudentClassroomInput={handleStudentClassroomInput}
                    classrooms={classrooms}
                    // getCurrentDate={getCurrentDate}
                    // booleanList={booleanList}
                    formattedDate={formattedDate}
                  />
                )
              })}
            </div>
          </div>

          <div className='sectionContainer statuses'>
            <div className='sectionHeaderContainer'>
              <div className='sectionTitleContainer'>
                <div className='sectionTitlePoint'></div>
                <h1 className='sectionTitle'>Status</h1>
              </div>
              <div className='sectionControlsContainer'>
                <div className='sectionControlContainer'>
                  <button onClick={addStatus} className='sectionControlButton'>
                    <CirclePlus stroke='white'  width='15'/>
                  </button>
                </div>
              </div>
            </div>
            {statusValError && (
              <p className='cardErrorMessage'>{statusValError}</p>
            )}
            <div className='studentScrollableBox'>
              {studentStatuses?.map((studentStatus, index) => {
                return (
                  <StudentStatusCard
                    key={studentStatus._id || studentStatus.uniqueId}
                    index={index}
                    studentStatus={studentStatus}
                    deleteStatus={deleteStatus}
                    handleStudentStatusInput={handleStudentStatusInput}
                    studentStatuses={studentStatuses}
                    booleanList={booleanList}
                  />
                )
              })}
            </div>
          </div>

          <div className='sectionContainer attachments'>
            <div className='sectionHeaderContainer'>
              <div className='sectionTitleContainer'>
                <div className='sectionTitlePoint'></div>
                <h1 className='sectionTitle'>Bijlagen</h1>
              </div>
              <div className='sectionControlsContainer'>
                {studentNewAttachments.length > 0 && (
                  <div className='notificationMessageInControls'>
                    <p>
                      U kiest {studentNewAttachments.length}{' '}
                      {studentNewAttachments.length === 1
                        ? 'bestand'
                        : 'bestanden'}
                    </p>
                  </div>
                )}
                <div className='sectionControlContainer'>
                  {/* button replaced with label in order to trigger the input */}
                  <label
                    className='sectionControlButton'
                    htmlFor='studentNewAttachments'
                  >
                    <input
                      type='file'
                      id='studentNewAttachments'
                      name='studentNewAttachments'
                      accept='application/pdf,
                        application/vnd.ms-excel,
                        image/png,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                      onChange={handleStudentAttachments}
                      multiple
                      className='maskedAttachmentInput'
                    />
                    <div className='sectionControlImage'>
                      <CirclePlus width='15' stroke='white'/>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className='studentScrollableBox'>
              {studentAttachments.length > 0 &&
                studentAttachments?.map((studentAttachment, index) => {
                  return (
                    <StudentAttachmentCard
                      key={studentAttachment._id}
                      studentAttachment={studentAttachment}
                      index={index}
                      isAdmin={isAdmin}
                      deleteAttachment={deleteAttachment}
                    />
                  )
                })}
            </div>
          </div>

          <div className='sectionContainer notes'>
            <div className='sectionHeaderContainer'>
              <div className='sectionTitleContainer'>
                <div className='sectionTitlePoint'></div>
                <h1 className='sectionTitle'>Aantekeningen</h1>
              </div>
              <div className='sectionControlsContainer'>
                {/* <div className='sectionControlContainer'>
                <div
                  className='sectionSearchContainer'
        
                >
                  <input
                    className='sectionSearchInput'
                    value={}
                    type='search'
                    placeholder='Zoeken...'
                    onChange={() => {}}
                  />
                  <button className='sectionSearchButton'>
                    <img
                      className='sectionControlImage'
                      src='/images/search.png'
                    ></img>
                  </button>
                </div>
              </div>*/}
                <div className='sectionControlContainer'>
                  {/* <button className='sectionControlButton'>
                    <img
                      className='sectionControlImage'
                      src='/images/print.png'
                    ></img>
                  </button> */}
                </div>
                <div className='sectionControlContainer'>
                  <button
                    // disabled={noteIndex !== null}
                    onClick={() => {
                      setNewNoteToggle(false)
                      setFilterToggle(!filterToggle)
                    }}
                    className={
                      filterToggle
                        ? 'sectionControlButtonShade'
                        : 'sectionControlButton'
                    }
                  >
                     <Filter stroke='white'  width='15'/>
                  </button>
                </div>
                <div className='sectionControlContainer'>
                  <button
                    disabled={noteIndex !== null}
                    onClick={() => {
                      setFilterToggle(false)
                      setNewNoteToggle(!newNoteToggle)
                    }}
                    className={
                      newNoteToggle
                        ? 'sectionControlButtonShade'
                        : 'sectionControlButton'
                    }
                  >
                    <CirclePlus stroke='white'  width='15'/>
                  </button>
                </div>
              </div>
            </div>
            {filterToggle && (
              <div className='filterCategoriesContainer'>
                {categoryNames?.map((category, index) => {
                  return (
                    <button
                      key={index}
                      className={`noteCategoryButton ${
                        selectedNotesCategories.includes(category)
                          ? 'chosenNoteCategory'
                          : ''
                      }`}
                      onClick={() =>
                        setSelectedNotesCategories((prevCategories) => {
                          if (prevCategories.includes(category)) {
                            return prevCategories.filter(
                              (cat) => cat !== category
                            )
                          } else {
                            return [...prevCategories, category]
                          }
                        })
                      }
                    >
                      {category}
                    </button>
                  )
                })}
              </div>
            )}
            {newNoteToggle && (
              <div className='noteContainer'>
                <div className='noteInputBox'>
                  <div className='noteHeaderCategoriesContainer'>
                    <label htmlFor='note'>Nieuwe Aantekening</label>{' '}
                    <div className='noteCategoriesContainer'>
                      {categoryNames?.map((category, index) => {
                        return (
                          <button
                            key={index}
                            name='category'
                            value={category}
                            onClick={(e) => handleNewNoteInput(e)}
                            className={`noteCategoryButton ${
                              newNote.category === category
                                ? 'chosenNoteCategory'
                                : ''
                            }`}
                          >
                            {category}
                          </button>
                        )
                      })}
                    </div>
                  </div>
                  <textarea
                    name='description'
                    value={newNote.description}
                    onChange={(e) => handleNewNoteInput(e)}
                    className='noteTextarea'
                    placeholder='Schrijf hier...'
                    id='note'
                  ></textarea>
                </div>
                <div className='noteInfoControlsContainer'>
                  <p>Gemaakt: {formatDate(formattedDate)}</p>

                  <div className='confirmationButtonsContainer'>
                    <button className='opslaan_btn'
                      disabled={newNote.description === '' ? true : false}
                      onClick={saveNewNote}
                    >
                      Opslaan
                      <CircleCheckBig width='15' stroke='white'/>
                    </button>
                    <button onClick={cancelNewNote} className='annuleren_btn nee-button'>
                      Annuleren
                      <CircleSlash2 width='15' stroke='white'/>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {noteIndex !== null && (
              <div className='noteContainer'>
                <div className='noteInputBox'>
                  <div className='noteHeaderCategoriesContainer'>
                    <label htmlFor='note'>Edit Aantekening</label>
                    <div className='noteCategoriesContainer'>
                      {categoryNames?.map((category, index) => {
                        // const isAdmin = auth.roles.includes(1991)

                        const isAuthor =
                          auth.userId === studentNotes[noteIndex].author
                        return (
                          <button
                            disabled={!isAuthor}
                            key={index}
                            name='category'
                            value={category}
                            onClick={(e) => handleEditedNoteInput(e)}
                            className={`noteCategoryButton ${
                              editedNote.category === category
                                ? 'chosenNoteCategory'
                                : ''
                            }`}
                          >
                            {category}
                          </button>
                        )
                      })}
                    </div>
                  </div>
                  <textarea
                    disabled={auth.userId !== studentNotes[noteIndex].author}
                    name='description'
                    value={editedNote.description}
                    onChange={(e) => handleEditedNoteInput(e)}
                    className='noteTextarea'
                    placeholder='Schrijf hier...'
                    id='note'
                  ></textarea>
                </div>
                <div className='noteInfoControlsContainer'>
                  <p>Gemaakt: {formatDate(editedNote.date)}</p>

                  <div className='confirmationButtonsContainer'>
                    {auth.userId !== studentNotes[noteIndex].author ? (
                      <button onClick={cancelEditedNote}>
                        Sluit
                        <CircleCheckBig width='15' stroke='white'/>
                      </button>
                    ) : (
                      <>
                        <button   style={{maxWidth: 'fit-content', padding: '3px 20px'}} 
                          disabled={
                            editedNote.description === '' ? true : false
                          }
                          onClick={saveEditedNote}
                        >
                          Opslaan
                          <CircleCheckBig width='15' stroke='white'/>
                        </button>
                        <button className='nee-button' onClick={cancelEditedNote}
                        style={{maxWidth: 'fit-content', padding: '3px 15px'}}
                        >
                          Annuleren
                              <CircleSlash2 width='15' stroke='white'/>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div
              className='studentScrollableBox'
              onSubmit={(e) => e.preventDefault()}
            >
              {filteredNotes.length > 0 && (
                <table className='list'>
                  <thead>
                    <tr>
                      {noteHeaders?.map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredNotes?.map((element, index) => {
                      const isAdmin = auth.roles.includes(1991)
                      const isAuthor = auth.userId === element.author
                      //
                      return (
                        <tr key={index} onClick={() => openEditedNote(index)}>
                          <td className='cell px-for-td'>{formatDate(element.date)}</td>
                          <td className='cell px-for-td'>{element.authorName}</td>
                          <td className='cell px-for-td'>{element.category}</td>
                          <td className='cell px-for-td'>
                            <span title={element.description}>
                              {element.description.length > 20
                                ? `${element.description.substring(0, 20)}...`
                                : element.description}
                            </span>
                          </td>
                          <td className='cell trashcan_container px-for-td'>
                            <button
                              disabled={
                                (!isAdmin && !isAuthor) || noteIndex !== null // only admin or the author of the note can delete his note. While the note is open, no deleting
                              }
                              onClick={(e) => {
                                e.stopPropagation() // does not let the parent onClick trigger
                                deleteNote(index)
                              }}
                              className={`noteIconContainer ${
                                (!isAdmin && !isAuthor) || noteIndex !== null
                                  ? 'faded'
                                  : ''
                              }`}
                            >
                                  <Trash2 width='20' stroke='var(--dark_orange)'/>
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </section>
      <PrintableStudentProfile
        student={student}
        organizations={organizations}
        studentClassrooms={studentClassrooms}
        classrooms={classrooms}
        filteredNotes={filteredNotes}
        formatDate={formatDate}
      />
    </>
  )
}

export default StudentProfile
